import React from 'react'

export const GSheetsIframe: React.FC<{ spreadsheetId: string | undefined; preview?: boolean }> = ({
  spreadsheetId,
  preview = false,
}) => {
  if (spreadsheetId == null) {
    return null
  }

  return (
    <iframe
      /* @ts-ignore: credentialless isn't fully supported yet */
      credentialless="true"
      src={`https://docs.google.com/spreadsheets/d/${spreadsheetId}${
        preview ? '/preview' : '?rm=embedded'
      }`}
      width="100%"
      height="100%"
      border="0"
    />
  )
}
